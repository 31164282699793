<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :span="10">
        <div style="width: 400px">
          <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="文章标题" prop="title">
              <div>
                <el-input v-model="form.title"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="文章类型" prop="type">
              <el-select style="width: 100%" v-model="form.type" clearable placeholder="请选择文章类型">
                <el-option
                    v-for="dict in dict.article_type"
                    :key="Number(dict.dictValue)"
                    :label="dict.dictLabel"
                    :value="Number(dict.dictValue)"/>
              </el-select>
            </el-form-item>
            <el-form-item label="是否展示" prop="isShow">
              <el-select style="width: 100%" v-model="form.isShow" clearable placeholder="请选择是否展示">
                <el-option
                    v-for="dict in dict.sys_yes_no"
                    :key="Number(dict.dictValue)"
                    :label="dict.dictLabel"
                    :value="Number(dict.dictValue)"/>
              </el-select>
            </el-form-item>
            <el-form-item label="是否置顶" prop="isTop">
              <el-select style="width: 100%" v-model="form.isTop" clearable placeholder="请选择是否置顶">
                <el-option
                    v-for="dict in dict.sys_yes_no"
                    :key="Number(dict.dictValue)"
                    :label="dict.dictLabel"
                    :value="Number(dict.dictValue)"/>
              </el-select>
            </el-form-item>
            <el-form-item label="文章封面" prop="fileId">
              <el-input type="number" v-show="false" v-model="form.fileId"></el-input>
              <el-upload
                  class="avatar-uploader"
                  :headers="{Authorization: token}"
                  :action="uploadUrl"
                  :show-file-list="false"
                  :multiple="false"
                  :on-success="handleImageSuccess"
                  :before-upload="beforeImageUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

            <el-form-item>
              <el-button :disabled="submitButtonDisabled" style="width: 100%" type="success" @click="handleSubmit">{{submitButtonText}}</el-button>
            </el-form-item>

          </el-form>
        </div>
      </el-col>
      <el-col :span="10">
        <div style="width: 100%">
          <div>
            <Tinymce
                ref="editor"
                v-model="form.content"
                width="500px"
                height="888px"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import store from "@/store";
import Tinymce from "@/components/Tinymce/Tinymce.vue";

export default {
  name: "index",
  components: {Tinymce},
  dicts: ['article_type', 'sys_yes_no'],
  data() {
    return {
      form: {
        title: '',
        type: null,
        isTop: null,
        isShow: null,
        content: '',
        fileId: null,
      },
      submitButtonText: '保 存',
      submitButtonDisabled: false,
      imageUrl: '',
      token: store.state.userinfo.token,
      uploadUrl: process.env.VUE_APP_BASE_URL + '/file/upload',
      rules: {
        title: [
          {required: true, message: '请输入文章标题', trigger: 'blur'},
          {min: 1, max: 50, message: '1~100字符', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择文章类型', trigger: ['blur', 'change']}
        ],
        isTop: [
          {required: true, message: '请选择是否置顶', trigger: ['blur', 'change']}
        ],
        isShow: [
          {required: true, message: '请选择是否展示', trigger: ['blur', 'change']}
        ],
        fileId: [
          {required: true, message: '请选择上传封面', trigger: 'blur'}
        ],
      },
    }
  },
  mounted() {
    console.log('innerHeight', innerHeight)

  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          this.$message.warning('请完整填写信息')
          return false;
        }
        this.$message.success('success')
        this.$api.article.save(this.form).then(res => {
          this.submitButtonText = '已 保 存'
          this.submitButtonDisabled = true
        }).catch(() => {
          this.submitButtonText = '正 在 保 存'
          this.submitButtonDisabled = '保 存'
        })

      })
    },

    handleImageSuccess(res, file) {
      if (Number(res.code) !== 200) {
        this.$message.error('上传失败，请联系管理员')
        return
      }
      this.form.fileId = res.data.id
      this.imageUrl = URL.createObjectURL(file.raw);
    },

    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt6M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt6M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt6M;
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 320px;
  height: 178px;
  display: block;
}
</style>

<style scoped lang="scss">
::v-deep .tox .tox-dialog--width-lg {
  height: 650px;
  max-width: 400px;
}

</style>
